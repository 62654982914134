<template>
  <f7-app v-bind="f7params">
    <!-- Left panel with cover effect-->
    <!--  <f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block>Left panel content goes here</f7-block>
      </f7-page>
    </f7-view>
    </f7-panel>-->

    <!-- Your main view, should have "view-main" class -->
    <!--<f7-view main class="safe-areas" url="/"></f7-view>-->

    <f7-view
      v-if="IS_LOGGED_IN"
      url="/sim-list/"
      main
      :stackPages="true"
      :allowDuplicateUrls="true"
      class="safe-areas"
      :key="keyViewMain"
    ></f7-view>

    <f7-view v-else url="/login/" class="safe-areas view-login"></f7-view>
  </f7-app>
</template>
<script>
import { ref, onMounted, computed, watch, Vue } from "vue";
import { useStore } from "vuex";
import { f7, f7ready } from "framework7-vue";
import { getDevice } from "framework7/lite-bundle";
import { useI18n } from "vue-i18n";
import { getDevicePushInfo } from "../js/helpers";
import cordovaApp from "../js/cordova-app.js";

import routes from "../js/routes.js";
//import store from '../js/store';

export default {
  setup() {
    const store = useStore();
    const device = getDevice();
    const { t } = useI18n();
   

    // Framework7 Parameters
    const f7params = {
      name: "GPS Secure Admin", // App name
      theme: "auto", // Automatic theme detection

      id: "com.gpssecureadmincontroller.app", // App bundle ID
      // App store
      //store: store,
      // App routes
      routes: routes,

      notification: {
        icon: '<img src="static/icons/favicon.png" class="icon-notification" alt="" />',
        title: "M2M Data Controller",
        closeTimeout: 3000,
        closeButton: true,
      },
      // Input settings
      input: {
        scrollIntoViewOnFocus: device.cordova && !device.electron,
        scrollIntoViewCentered: device.cordova && !device.electron,
      },
      // Cordova Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
    };
    // Login screen data
    /*const username = ref('');
      const password = ref('');

      const alertLoginData = () => {
        f7.dialog.alert('Username: ' + username.value + '<br>Password: ' + password.value, () => {
          f7.loginScreen.close();
        });
      }*/
    const keyViewMain = ref(Date.now());
    let IS_LOGGED_IN = computed(() => store.state.IS_LOGGED_IN);
    let ERROR = computed(() => store.getters.ERROR);
    watch(IS_LOGGED_IN, (newVal) => {
      keyViewMain.value = ++keyViewMain.value;
      if (device.cordova && !newVal) {
        cordovaApp.unregisterPush();
      }
    });
    watch(ERROR, (err) => {
      if (err.data) err = err.data;

      let msg = t("IssueDetected");
      let title = t("Error");

      if (err.MajorCode === "100" && err.MinorCode === "1003") {
        title = t("AccessRestricted");
        msg = t("DemoRestricted");
      }

      if (err.MajorCode === "100" && err.MinorCode === "1006") {
        f7.dialog.confirm(t("NoCredits"), async () => {
          f7.views.main.router.navigate({
            name: "recharge-credits",
          });
        });
      }

      switch (err.method) {
        case "LOGIN":
          if (err.MajorCode === "100") {
            msg = t("IncorrectLoginPassword");
          }
          break;
        case "RESET_PASSWORD":
          if (err.MajorCode === "100" && err.MinorCode === "1004") {
            msg = t("WrongCurrentPassword");
          }
          break;
        case "ACCOUNT_EDIT":
          if (err.MajorCode === "100" && err.MinorCode === "1002") {
            msg = t("EmailAlreadyRegistered");
          }
          break;
        case "SIM_SET_SUSPEND":
        case "SIM_SET_RESUME":
        case "SIM_SET_TERMINATE":
          if (err.MajorCode === "001") {
            msg = t("SimError") + "</br>";
            if (err.Data && err.Data.length) {
              err.Data.forEach((el) => {
                if (el.Messages && el.Messages.length && el.Messages[0]) {
                  msg += el.Key + " - ";
                  el.Messages.forEach((errMessage) => {
                    msg += errMessage + ",";
                  });
                  msg = msg.slice(0, -1);
                  msg += "</br>";
                }
              });
            }
          }
          break;

        case "CUSTOMER_EDIT":
          if (err.MajorCode === "200") {
            msg = err?.Data?.error || t("IssueDetected");
            if (msg === "loginName incorrect") msg = t("LoginNameInUse");
            if (msg === "email incorrect") msg = t("EmailInUse");
          }
          break;

        case "SEND_CUSTOMER_REG_LETTER":
          if (err.MajorCode === "100") {
            msg = err?.Data || t("IssueDetected");
            if (msg === "CHECK DOMAIN SETTINGS")
              msg = t("CheckCustomerSettings");
          }
          break;

        case "SEND_ASSET_ACTIVATION_LETTER":
          msg = err?.Data || t("IssueDetected");
          if (msg === "Client type incorrect")
            msg = t("ActivationLetterErrorAccType");
          break;
      }

      f7.dialog.alert(msg, title);
    });
    onMounted(() => {
 
      f7ready(() => {
        // Init cordova APIs (see cordova-app.js)
        if (device.cordova) {
          cordovaApp.init(f7);
        }
        getDevicePushInfo();

        // Call F7 APIs here

      

  

       
      });
    });

    return {
       f7params,
      keyViewMain,
      IS_LOGGED_IN,
      //username,
      //password,
      //alertLoginData
    };
  },
};
</script>